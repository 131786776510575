import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "M11.26 6h9.47c.65 0 1.23.02 1.75.09C25.25 6.4 26 7.7 26 11.26v6.32c0 3.56-.75 4.86-3.52 5.17-.52.07-1.09.09-1.75.09h-9.47c-.65 0-1.23-.02-1.75-.09-2.77-.31-3.52-1.61-3.52-5.17v-6.32c0-3.56.75-4.86 3.52-5.17.52-.07 1.1-.09 1.75-.09M11 12.32h3.68M17.1 12.32h.8M20.5 12.32h.8M10.74 18.11h10.53M11 26h10"
    }, null, -1)
  ])))
}
export default { render: render }